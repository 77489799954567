<template>
  <div class="search-wrapper">
    <input type="text" ref="searchInput" v-model="searchTerm" @input="fetchData" :placeholder="placeholder" class="search-input" />
    <ul v-if="locations.length" class="results-dropdown">
      <li v-for="location in locations" :key="location._id" class="d-flex justify-content-between align-items-center">
        <div>{{ location.city }}, {{ location.country }}</div>
        <div>
          <button class="btn btn-outline-secondary" @click="addToCategory(location, 'traveled')">
            <img src="../assets/travelled.png" alt="Favorite" class="icon" />
          </button>
          <button class="btn btn-outline-secondary" @click="addToCategory(location, 'favorites')">
            <img src="../assets/favourite.png" alt="Favorite" class="icon" />
          </button>
          <button class="btn btn-outline-secondary" @click="addToCategory(location, 'planning')">
            <img src="../assets/planning2.png" alt="Favorite" class="icon" />
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";

export default {
  data() {
    return {
      searchTerm: "",
      locations: [],
    };
  },
  props: {
    placeholder: {
      type: String,
    },
  },
  mounted() {
    // Add event listener to handle clicks outside the search input
    document.addEventListener("click", this.handleClickOutside, true);
  },
  unmounted() {
    // Clean up the event listener when the component is destroyed
    document.removeEventListener("click", this.handleClickOutside, true);
  },
  created() {
    this.fetchData = _.debounce(this.fetchData, 300);
  },
  watch: {
    searchTerm(newVal) {
      if (newVal.length > 0) {
        this.fetchData();
      } else {
        this.locations = [];
      }
    },
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/location/findlocation`, {
          params: { location: this.searchTerm },
        });
        this.locations = response.data;
      } catch (error) {
        console.error("Failed to fetch data:", error);
        this.locations = [];
      }
    },
    addToCategory(location, category) {
      this.$emit("add-to-category", location, category);
      this.locations = [];
      this.searchTerm = "";
    },
    handleClickOutside(event) {
      const searchInput = this.$refs.searchInput;
      if (searchInput && !searchInput.contains(event.target)) {
        this.searchTerm = "";
      }
    },
  },
};
</script>

<style scoped>
.search-wrapper {
  position: relative;
  width: 100%;
  max-width: 500px; /* Adjust based on your design preference */
}
.search-input {
  width: 100%;
  padding: 8px 10px 8px 40px; /* Adjust padding to make room for icon */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #fff;
  /*  background imatge assets/seach-icon.png */
  background-image: url("../assets/search-icon.png");
  background-position: 10px 10px;
  /* background-position: 10px 10px; Adjust position based on padding */
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.results-dropdown {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  background: white;
  z-index: 1000; /* Make sure it is above other content */
}
.results-dropdown li {
  padding: 8px 12px;
  cursor: pointer;
}
.results-dropdown li:hover {
  background-color: #f8f8f8;
}
.icon {
  height: 25px; /* Adjust the height as needed */
  width: auto; /* Maintains aspect ratio */
  vertical-align: middle; /* Aligns icon with text */
}
</style>
