<template>
  <div>
    <div v-if="user">
      <h2>{{ user.name }}</h2>
      <h2>{{ user.email }}</h2>
      <img :src="user.picture" alt="user picture" />
      <button @click="logout">Logout</button>
      <router-link to="/">
        <button class="btn btn-lg entranceButtons color1Buttons">Main Page</button>
      </router-link>
    </div>

    <div v-else>
      <h2>Google Login</h2>
      <GoogleLogin :callback="handleGoogleLogin" prompt />
      <router-link to="/">
        <button class="btn btn-lg entranceButtons color1Buttons">Main Page</button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { decodeCredential, googleLogout } from "vue3-google-login";
import axios from "axios";
import { useAuth } from "../utils/useAuth";

export default {
  name: "GoogleLoginPage",
  data() {
    return {
      user: null,
    };
  },
  created() {
    this.initializeUser();
  },
  methods: {
    initializeUser() {
      const { isAuthenticated, verifyToken } = useAuth();
      verifyToken(); // Ensure this function is capable of setting isAuthenticated properly
      if (isAuthenticated.value) {
        const token = localStorage.getItem("jwt");
        if (token) {
          // Assuming the JWT contains encoded user data you want to display
          // This should be securely decoded server-side in a real application
          this.user = decodeCredential(token); // Adjust decodeCredential to properly decode JWT and extract user data
        }
      }
    },
    handleGoogleLogin(response) {
      console.log("Login successful, decoding credentials...");
      this.user = decodeCredential(response.credential);
      console.log("User data:", this.user);
      this.googleAuth();
    },
    logout() {
      localStorage.removeItem("jwt");
      googleLogout();
      this.$emit("update:isAuthenticated", false); // Update parent component if necessary
      this.user = null;
      console.log("Logout successful.");
    },
    googleAuth() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/api/user/google-auth`, {
          email: this.user.email,
        })
        .then((res) => {
          localStorage.setItem("jwt", res.data.token);
          this.$emit("update:isAuthenticated", true); // Update parent component if necessary
          console.log("Authentication success:", res.data);
        })
        .catch((err) => {
          console.error("Authentication error:", err.message);
          this.$emit("update:isAuthenticated", false); // Update parent component if necessary
        });
    },
  },
};
</script>
