<template>
  <div class="map-container my-3" style="height: 400px; width: 100%">
    <l-map @click="handleMapClick" :zoom="7" :center="center">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

      <!-- Markers for traveled -->
      <l-marker
        v-for="coordinate in traveled"
        :key="coordinate._id"
        :lat-lng="[coordinate.coordinate_x, coordinate.coordinate_y]"
        :icon="traveledIcon"
      ></l-marker>

      <!-- Markers for favorites -->
      <l-marker
        v-for="coordinate in favorites"
        :key="coordinate._id"
        :lat-lng="[coordinate.coordinate_x, coordinate.coordinate_y]"
        :icon="favoritesIcon"
      ></l-marker>

      <!-- Markers for planning -->
      <l-marker
        v-for="coordinate in planning"
        :key="coordinate._id"
        :lat-lng="[coordinate.coordinate_x, coordinate.coordinate_y]"
        :icon="planningIcon"
      ></l-marker>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import pinYellow from "../assets/pin-yellow.png";
import pinRed from "../assets/pin-red.png";
import pinBlack from "../assets/pin-black.png";

export default {
  name: "TurkeyMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },

  props: {
    traveled: {
      type: Array,
      default: () => [],
    },
    favorites: {
      type: Array,
      default: () => [],
    },
    planning: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      center: [39.925533, 32.866287], // Coordinates for Ankara, Turkey
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      // Example icon definitions
      traveledIcon: L.icon({ iconUrl: pinBlack, iconSize: [50, 50], iconAnchor: [25, 50] }),
      favoritesIcon: L.icon({ iconUrl: pinRed, iconSize: [50, 50], iconAnchor: [25, 50] }),
      planningIcon: L.icon({ iconUrl: pinYellow, iconSize: [50, 50], iconAnchor: [25, 50] }),
    };
  },
  methods: {
    handleMapClick(event) {
      // Retrieve map instance from the event
      const map = event.target;
      if (map) {
        const latLng = map.mouseEventToLatLng(event.originalEvent);
        // Emit an event with latitude and longitude
        console.log("Map clicked at:", latLng);
        this.$emit("mapClicked", { lat: latLng.lat, lng: latLng.lng });
      }
    },
  },
};
</script>

<style>
/* Ensure the map container has a defined height, adjust as needed */
.map-container {
  height: 100%;
}
</style>
