<template>
  <div>
    <HeaderComponent :isAuth="isAuth"></HeaderComponent>
    <div v-if="!profileData.user.username">
      <h2>Add a username</h2>
      <form @submit.prevent="submitUsername">
        <input v-model="newUsername" placeholder="Enter username" required />
        <button type="submit">Submit</button>
      </form>
    </div>
    <div v-else>
      <h1>Welcome, {{ profileData.user.username }}</h1>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import axios from "axios";

export default {
  name: "AddUserNamePage",
  components: {
    HeaderComponent,
  },
  data() {
    return {
      profileData: {
        user: {
          username: null,
        },
      },
      newUsername: "",
      isAuth: true, // You would typically get this from a store or prop
    };
  },
  created() {
    this.fetchUserProfile();
  },
  methods: {
    fetchUserProfile() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/api/user/profile`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        })
        .then((response) => {
          console.log("Profile data:", response.data);
          this.profileData = response.data;
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
        });
    },
    submitUsername() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/api/user/addUserName`, {
          email: this.profileData.user.email,
          username: this.newUsername,
        })
        .then(() => {
          this.profileData.user.username = this.newUsername;
          alert("Username updated successfully!");
        })
        .catch((error) => {
          alert("Error updating username!");
          console.error("There was an error:", error);
        });
    },
  },
};
</script>

<style scoped>
input,
button {
  padding: 8px;
  margin: 5px;
}
</style>
