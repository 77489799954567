<template>
  <div>
    <HeaderComponent :isAuth="isAuth"></HeaderComponent>

    <div>HOME PAGE -test -- 2</div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import { useAuth } from "../utils/useAuth";

export default {
  name: "MainPage",
  components: {
    HeaderComponent,
  },
  setup() {
    const { isAuthenticated } = useAuth();
    return { isAuth: isAuthenticated };
  },

  methods: {},
};
</script>

<style scoped></style>
