<template>
  <div class="body">
    <div id="app" class="container">
      <div class="alert alert-danger" role="alert">This web app is still under development you may not able to use functions</div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.body {
  /* background-color: #f0f0f0; */
}
</style>
