<!-- Vue component -->
<template>
  <HeaderComponent :isAuth="isAuth"></HeaderComponent>
  <div>About Page v2 test</div>
</template>
<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import { useAuth } from "../utils/useAuth";

export default {
  name: "AboutPage",
  components: {
    HeaderComponent,
  },
  setup() {
    const { isAuthenticated } = useAuth();
    return { isAuth: isAuthenticated };
  },
};
</script>

<style scoped></style>
