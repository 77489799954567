<template>
  <div>
    <header class="p-3 text-bg-dark">
      <div class="container" bis_skin_checked="1">
        <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start" bis_skin_checked="1">
          <!-- <a href="/" class="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
            <svg class="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap">
              <use xlink:href="#bootstrap"></use>
            </svg>
          </a> -->

          <!--  logo -->
          <router-link to="/">
            <img
              alt="Vue logo"
              src="../assets/logo.png"
              class="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none"
              width="70px"
              height="70px"
            />
          </router-link>

          <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
            <router-link to="/">
              <li><a href="#" class="hearder-links nav-link px-2 text-white">Home</a></li>
            </router-link>
            <router-link to="/about">
              <li><a href="#" class="hearder-links nav-link px-2 text-white">About</a></li>
            </router-link>
            <router-link to="/explore">
              <li><a href="#" class="hearder-links nav-link px-2 text-white">Explore</a></li>
            </router-link>
          </ul>

          <!-- <form class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
            <input type="search" class="form-control form-control-dark text-bg-dark" placeholder="Search..." aria-label="Search" />
          </form> -->

          <!-- <div class="text-end" bis_skin_checked="1">
            <button type="button" class="btn btn-outline-light me-2">Login</button>
            <button type="button" class="btn btn-warning">Sign-up</button>
          </div> -->

          <!-- if user authenticated  show -->
          <div v-if="isAuth" class="text-end" bis_skin_checked="1">
            <router-link to="/profile">
              <button type="button" class="btn btn-outline-light me-2">Profile</button>
            </router-link>
            <button type="button" @click="logout" class="btn btn-warning">Logout</button>
          </div>
          <!-- if user not authenticated  show -->
          <div v-else class="text-end" bis_skin_checked="1">
            <router-link to="/google-login">
              <button type="button" class="btn btn-outline-light me-2">Login</button>
            </router-link>
            <router-link to="/google-login">
              <button type="button" class="btn btn-warning">Sign-up</button>
            </router-link>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { googleLogout } from "vue3-google-login";

export default {
  name: "HeaderComponent",
  props: {
    isAuth: {
      type: Boolean,
      required: true,
    },
  },
  return: {},
  methods: {
    logout() {
      localStorage.removeItem("jwt");
      googleLogout();
      this.$emit("update:isAuthenticated", false); // Update parent component if necessary
      this.user = null;
      console.log("Logout successful.");

      // refresh the vue states
      this.$router.go();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
  padding: 20px;
}
a {
  color: #42b983;
}
.hearder-links {
  border-bottom: 1px solid transparent; /* Always have a border, but make it transparent */
}
/* hearder-links hower white border  */
.hearder-links:hover {
  border-bottom: 1px solid white;
}
</style>
