<!-- Vue component -->
<template>
  <div>
    <HeaderComponent :isAuth="isAuth"></HeaderComponent>
  </div>

  <div class="mt-5">
    <div class="row">
      <div class="col-sm">
        <div class="box p-3">
          <div class="col d-flex align-items-center justify-content-between mb-3">
            <h4>Top Destinations Explored</h4>
            <img alt="pin-black" src="../assets/pin-black.png" class="mb-2 mb-lg-0" width="25px" height="25px" />
          </div>
          <hr />

          <!-- Dynamic content here -->
          <ul class="results-dropdown">
            <li v-for="item in top_traveled" :key="item._id" class="d-flex justify-content-between align-items-center">
              <div>{{ item.destination_info.city }}, {{ item.destination_info.country }}</div>
              <button class="btn btn-outline-secondary">{{ item.count }}</button>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm">
        <div class="box p-3">
          <div class="col d-flex align-items-center justify-content-between mb-3">
            <h4>Top Favorites</h4>
            <img alt="pin-red" src="../assets/pin-red.png" class="mb-2 mb-lg-0" width="25px" height="25px" />
          </div>
          <hr />

          <!-- Dynamic content here -->
          <ul class="results-dropdown">
            <li v-for="favorite in top_favorites" :key="favorite._id" class="d-flex justify-content-between align-items-center">
              <div>{{ favorite.destination_info.city }}, {{ favorite.destination_info.country }}</div>
              <button class="btn btn-outline-secondary">{{ favorite.count }}</button>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm">
        <div class="box p-3">
          <div class="col d-flex align-items-center justify-content-between mb-3">
            <h4>Top Planinng Destinations</h4>
            <img alt="pin-yellow" src="../assets/pin-yellow.png" class="mb-2 mb-lg-0" width="25px" height="25px" />
          </div>
          <hr />

          <!-- Dynamic content here -->
          <ul class="results-dropdown">
            <li v-for="plan in top_planning" :key="plan._id" class="d-flex justify-content-between align-items-center">
              <div>{{ plan.destination_info.city }}, {{ plan.destination_info.country }}</div>
              <button class="btn btn-outline-secondary">{{ plan.count }}</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import axios from "axios";
import { ref, onMounted } from "vue";
import { useAuth } from "../utils/useAuth";

export default {
  name: "ExplorePage",
  components: {
    HeaderComponent,
  },
  setup() {
    // app.get("/travel-data", async (req, res) => {

    const top_traveled = ref([]);
    const top_favorites = ref([]);
    const top_planning = ref([]);
    const { isAuthenticated } = useAuth();

    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/user/top-destinations`);
        top_traveled.value = response.data.traveled;
        top_favorites.value = response.data.favorites;
        top_planning.value = response.data.planning;
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    onMounted(() => {
      fetchData();
    });
    return {
      top_traveled,
      top_favorites,
      top_planning,
      isAuth: isAuthenticated,
    };
  },
  data() {
    return {};
  },
  methods: {
    // /travel-data
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center; /* Centers the child horizontally */
  align-items: center; /* Centers the child vertically */
  min-height: 20vh; /* Ensures a minimal height */
  width: 50%; /* Use full width to give more room */
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: left;
  padding: 20px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%; /* Adjust this as needed to control the width of the app container */
}
.box {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
</style>
