<template>
  <div>
    <HeaderComponent :isAuth="isAuth"></HeaderComponent>

    <div v-if="isAuth">
      <!-- if the user has no username requeset them to create one -->
      <div v-if="!profileData.user.username">
        <div class="alert alert-warning" role="alert">
          You have not set a username yet. Please set a username to continue

          <router-link to="/add-username" class="btn btn-warning">Set Username</router-link>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-warning" role="alert">Welcome, {{ profileData.user.username }}</div>
      </div>

      <!-- SEARCH BAR -->
      <div class="container d-flex justify-content-center align-items-center">
        <div id="app">
          <search-dropdown @add-to-category="handleAddToCategory" :placeholder="placeholder"> </search-dropdown>
        </div>
      </div>

      <!-- MAP VIEWS -->
      <div class="">
        <TurkeyMap @mapClicked="handleMapClick" :traveled="traveled" :favorites="favorites" :planning="planning"></TurkeyMap>
      </div>

      <!-- SECTION OF Destinations Explored, Top Picks, Next Stops -->
      <div class="">
        <div class="row">
          <div class="col-sm">
            <div class="box p-3">
              <div class="col d-flex align-items-center justify-content-between mb-3">
                <h4>Destinations Explored</h4>
                <img alt="pin-black" src="../assets/pin-black.png" class="mb-2 mb-lg-0" width="25px" height="25px" />
              </div>
              <hr />

              <!-- Dynamic content here -->
              <ul class="results-dropdown">
                <li v-for="item in traveled" :key="item._id" class="d-flex justify-content-between align-items-center">
                  <div>{{ item.city }}, {{ item.country }}</div>
                  <button @click="removeFromCategory(item, 'traveled')" class="btn btn-outline-secondary">X</button>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm">
            <div class="box p-3">
              <div class="col d-flex align-items-center justify-content-between mb-3">
                <h4>Top Picks</h4>
                <img alt="pin-red" src="../assets/pin-red.png" class="mb-2 mb-lg-0" width="25px" height="25px" />
              </div>
              <hr />

              <!-- Dynamic content here -->
              <ul class="results-dropdown">
                <li v-for="favorite in favorites" :key="favorite._id" class="d-flex justify-content-between align-items-center">
                  <div>{{ favorite.city }}, {{ favorite.country }}</div>
                  <button @click="removeFromCategory(favorite, 'favorites')" class="btn btn-outline-secondary">X</button>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm">
            <div class="box p-3">
              <div class="col d-flex align-items-center justify-content-between mb-3">
                <h4>Next Stops</h4>
                <img alt="pin-yellow" src="../assets/pin-yellow.png" class="mb-2 mb-lg-0" width="25px" height="25px" />
              </div>
              <hr />

              <!-- Dynamic content here -->
              <ul class="results-dropdown">
                <li v-for="plan in planning" :key="plan._id" class="d-flex justify-content-between align-items-center">
                  <div>{{ plan.city }}, {{ plan.country }}</div>
                  <button @click="removeFromCategory(plan, 'planning')" class="btn btn-outline-secondary">X</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h1>You are not logged in</h1>
    </div>
  </div>
</template>

<script>
import { useAuth } from "../utils/useAuth";

import axios from "axios";
import { ref, onMounted } from "vue";
import SearchDropdown from "@/components/SearchDropdown.vue";
import TurkeyMap from "@/components/TurkeyMap.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: "ProfilePage",
  components: {
    SearchDropdown,
    TurkeyMap,
    HeaderComponent,
  },
  setup() {
    const { isAuthenticated } = useAuth();
    const profileData = ref({
      title: "",
      user: {
        id: "",
        email: "",
        username: "-", // Assuming you want this, even if it's not provided initially
      },
    });

    const traveled = ref([]);
    const favorites = ref([]);
    const planning = ref([]);

    const retrieveCategories = () => {
      if (profileData.value && profileData.value.user) {
        axios
          .get(`${process.env.VUE_APP_API_URL}/api/user/userCategory`, {
            params: {
              userId: profileData.value.user.id,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((response) => {
            console.log("User Categories:", response.data);
            traveled.value = response.data.travel_info.traveled;
            favorites.value = response.data.travel_info.favorites;
            planning.value = response.data.travel_info.planning;
          })
          .catch((error) => {
            console.error("Error fetching categories:", error.message);
          });
      }
    };

    const getProfile = () => {
      axios
        .get(`${process.env.VUE_APP_API_URL}/api/user/profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          profileData.value = response.data;
          console.log("Profile data:", profileData.value);
          retrieveCategories();
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error.message);
        });
    };

    onMounted(() => {
      getProfile();
    });

    return {
      isAuth: isAuthenticated,
      profileData,
      traveled,
      favorites,
      planning,
      placeholder: "Search destinations...",
    };
  },
  methods: {
    handleAddToCategory(location, category) {
      console.log("Added to favorites:", location._id);
      console.log("User ID:", this.profileData.user.id);
      // console.log("Coordinate ID:", coordinate._id);

      // addCoordinateToCategory { userId, coordinateId, category } catoegory is favorites
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/user/addCoordinateToCategory`,
          {
            email: this.profileData.user.email,
            coordinateId: location._id,
            category: category,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((response) => {
          console.log("category added", response.data);
          if (category === "traveled") {
            this.traveled.push(location);
          } else if (category === "favorites") {
            this.favorites.push(location);
          } else if (category === "planning") {
            this.planning.push(location);
          }
        })
        .catch((error) => {
          console.error("Failed to add to favorites:", error.message);
        });
    },

    // Remove from favorites
    removeFromCategory(coordinate, category) {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/user/removeCategory`,
          {
            userId: this.profileData.user.id,
            coordinateId: coordinate._id,
            category: category,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((response) => {
          console.log("Removed from favorites:", response.data);
          if (category === "traveled") {
            this.traveled = this.traveled.filter((item) => item._id !== coordinate._id);
          } else if (category === "favorites") {
            this.favorites = this.favorites.filter((favorite) => favorite._id !== coordinate._id);
          } else if (category === "planning") {
            this.planning = this.planning.filter((plan) => plan._id !== coordinate._id);
          }
        })
        .catch((error) => {
          console.error("Failed to remove from favorites:", error.message);
        });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center; /* Centers the child horizontally */
  align-items: center; /* Centers the child vertically */
  min-height: 20vh; /* Ensures a minimal height */
  width: 50%; /* Use full width to give more room */
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: left;
  padding: 20px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%; /* Adjust this as needed to control the width of the app container */
}
.box {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
</style>
