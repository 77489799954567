import { createRouter, createWebHistory } from "vue-router";

// Import your components here
import MainPage from "../pages/MainPage.vue";
// import LoginPage from "../pages/LoginPage.vue";
// import RegisterPage from "../pages/RegisterPage.vue";
import AboutPage from "../pages/AboutPage.vue";
import ExplorePage from "../pages/ExplorePage.vue";
import GoogleLoginPage from "../pages/GoogleLoginPage.vue";
import ProfilePage from "../pages/ProfilePage.vue";
import { useAuth } from "../utils/useAuth";
import NotFoundComponent from "../pages/NotFoundComponent.vue"; // Import the NotFound component
import AddUserNamePage from "../pages/AddUserNamePage.vue";
// import UnderDevelopment from "../pages/UnderDevelopmentPage.vue";

const routes = [
  { path: "/", name: "MainPage", component: MainPage },
  // { path: "/login", name: "LoginPage", component: LoginPage },
  // { path: "/register", name: "RegisterPage", component: RegisterPage },
  {
    path: "/explore",
    name: "ExplorePage",
    component: ExplorePage,
  },
  {
    path: "/google-login",
    name: "GoogleLoginPage",
    component: GoogleLoginPage,
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/about",
    name: "AboutPage",
    component: AboutPage,
  },
  {
    path: "/add-username",
    name: "AddUserNamePage",
    component: AddUserNamePage,
    meta: { requiresAuth: true },
  },
  // Add more routes as needed
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFoundComponent }, // Catch-all route
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// In router/index.js
router.beforeEach(async (to, from, next) => {
  const { isAuthenticated, verifyToken } = useAuth();
  await verifyToken(); // Ensure this completes before moving on

  if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated.value) {
    next({ name: "GoogleLoginPage" });
  } else {
    next();
  }
});

export default router;
