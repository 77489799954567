<template>
  <div>
    <h1>404 Not Found</h1>
    <p>The page you are looking for does not exist. Click <router-link to="/">here</router-link> to go back home.</p>
  </div>
</template>

<script>
export default {
  name: "NotFoundComponent",
};
</script>
