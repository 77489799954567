import { ref } from "vue";
import axios from "axios";

const isAuthenticated = ref(false);

// In useAuth.js
const verifyToken = () => {
  return new Promise((resolve) => {
    const token = localStorage.getItem("jwt");
    if (token) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/api/user/verifyToken`, { headers: { token } })
        .then((res) => {
          if (res.status === 200) {
            isAuthenticated.value = true;
            resolve(true);
          } else {
            isAuthenticated.value = false;
            resolve(false);
          }
        })
        .catch(() => {
          isAuthenticated.value = false;
          resolve(false);
        });
    } else {
      isAuthenticated.value = false;
      resolve(false);
    }
  });
};

export function useAuth() {
  const setUserAuthenticated = (status) => {
    isAuthenticated.value = status;
  };

  // Call verifyToken on initialization
  verifyToken();

  return { isAuthenticated, setUserAuthenticated, verifyToken };
}
